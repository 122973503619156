.b-form {
    background-color: $primary;
    padding: 60px 0;

    &__form {

        margin-bottom: 40px;
        background-color: $white;
        padding: 35px 50px;
        color: $primary;
        margin-top: -95px;
        position: relative;
        z-index: 2;

        @include media-breakpoint-down(md) {
            padding: 20px;
            margin-top: 0;
            margin-bottom: 0;
        }

        h2, .h2 {
            margin-bottom: 20px;
            font-size: 50px;
            line-height: 1;
            font-weight: 300;
        }

        p, .p {
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 22px;
            font-weight: 500;
        }

        .btn.btn-primary {
            font-size: 22px;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }

    }

    .tag-title {
        font-size: 23px;
        font-weight: 500;
        color: rgba($white, .5);
        margin-bottom: 25px;
    }

    &__tag {
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid $white;
        padding: 0 35px;
        line-height: 56px;
        font-weight: bold;
        font-size: 25px;
        color: $white;
        text-decoration: none;

        &:hover {
            background-color: #ce5023;
            border-color: #ce5023;
            color: $white;
        }

    }

}
