.b-header {

    background-color: $white;
    padding: 12px 0;
    color: $primary;

    &__logo {
        width: 251px;
        height: 102px;
        display: block;

        @include media-breakpoint-down(lg) {
            width: 180px;
            height: auto;
        }

        img {
            width: 100%;
            height: 100%;
        }

    }

    &__item {

        margin-right: 90px;
        font-size: 13px;

        &_nm {
            margin-right: 0 !important;
            padding: 15px 0 10px;
        }

        @include media-breakpoint-down(lg) {
            margin-right: 40px;
            font-size: 12px;
        }

        .title {

            display: block;
            font-weight: bold;
            font-size: 24px;
            margin-bottom: 8px;
            line-height: 1;

            @include media-breakpoint-down(lg) {
                font-size: 20px;
            }

            a {
                text-decoration: none;
            }

        }

    }

    &__text {
        font-size: 13px;
        font-weight: 500;
        line-height: 17px;
        padding-top: 12px;
    }

    .creator a {
        text-decoration: none !important;
    }

}