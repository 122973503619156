body {

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    &.fixed {

        overflow: hidden;
    }

}

.btn {
    &.btn-primary {
        outline: none !important;
        border: none !important;
        box-shadow: none !important;
        border-radius: 0;
        background-color: $red;
        text-decoration: none !important;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 14px 17px;
        line-height: 1;
        &:hover {
            background-color: #ce5023;
        }
        &:active {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, .35) !important;
            background-color: $red !important;
        }
    }
    &.btn-outline-primary {
        outline: none !important;
        box-shadow: none !important;
        border: 1px solid $red;
        border-radius: 0;
        background-color: $white;
        color: $red;
        text-decoration: none !important;
        font-size: 1.25rem;
        font-weight: bold;
        padding: 14px 17px;
        line-height: 1;
        &:hover {
            background-color: $red;
            color: #ffffff;
        }
        &:active {
            box-shadow: inset 0 0 10px rgba(0, 0, 0, .35) !important;
            background-color: $red !important;
            border-color: $red;
        }
    }
}

.i-checkbox {
    display: none;

    & + label {
        font-size: 13px;
        color: #b5acbc;
        line-height: 16px;
        display: block;
        padding-left: 35px;
        position: relative;
        cursor: pointer;

        &:before {
            position: absolute;
            top: 1px;
            left: 0;
            width: 23px;
            height: 23px;
            border: 1px solid rgba(29, 82, 145, .5);
            content: '';
        }

        &:hover:before {
            border-color: $primary;
        }

        &.have-error {
            &:before {
                border-color: $red;
                background-color: rgba($red, .1);
            }
        }

    }

    &:checked + label {
        &:before {
            background-repeat: no-repeat;
            background-position: 50% 50%;
            border-color: $primary;
            background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAALCAMAAACXmSduAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAnFBMVEUdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpEdUpH///8CNKhZAAAAMnRSTlMAOOegBD/yqUf12UuNC0/52h5b+8QPWfrUGfHME2L8zRTh/sUQUb4MSbYJQPOtBjmfA1P0/zkAAAABYktHRDM31XxeAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH4gcWATgwYI4iGgAAAHBJREFUCNdjYEAARiZmFiQuK5uRETuCy8FpZGTExcDAzcML4vLxA7kCggxCwkYiogwMYuJAroQkA4MUkJaWkZUDUvIKQHVCIJaiEpBQVgGbowrSCARq6lCDNUAGG2lqwW3S1jEy0tVDcok+k4EhlAkAniQMHPtGs9UAAAAASUVORK5CYII=');
        }
    }

}

.form-group {
    margin-bottom: 20px;
    &:last-child {
        margin-bottom: 0;
    }
}

.form-control {
    height: 46px;
    line-height: 46px;
    padding: 0 20px;
    font-size: 18px;
    color: $primary;
    border: 1px solid rgba($primary, .5);
    border-radius: 0;
    outline: none !important;
    box-shadow: none !important;
    background-color: transparent;
    &:hover, &:focus {
        border: 1px solid rgba($primary, 1);
        background-color: $white;
    }

    @include media-breakpoint-down(md) {
        font-size: 16px;
        padding: 0 10px;
    }

    &.have-error {
        border-color: $red;
        background-color: rgba($red, .1);
        color: $red;
        &::placeholder {
            color: $red
        }
    }

}

select.form-control:not([size]):not([multiple]) {
    height: 46px !important;
    appearance: textfield;
    background-size: 17px 9px;
    background-position: calc(100% - 20px) 50%;
    background-repeat: no-repeat;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAJCAMAAADjNjc+AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAb1BMVEU5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypo5Ypr////n23RBAAAAI3RSTlMA4aQEBqralK0ItIwBjwq6iA3CfhDGdxPN/m0q0v1n/F7oWNz/NsEAAAABYktHRCS0BvmZAAAACXBIWXMAAAsSAAALEgHS3X78AAAAB3RJTUUH4gcXBDcuo26mOAAAAF5JREFUCNdVjUcSgDAUQr8ae01i19i4/x1t0SgbmMcwkGU7ZMRcj3wE7AVhhJiSFFGoQZajOL1Anl2AC8i7khD8sLJC/cxrVCU1bdebi75rh1FNn1OalJoX+mlZN512dUMFCw+TqEAAAAAASUVORK5CYII=');

    @include media-breakpoint-down(md) {
        background-position: calc(100% - 10px) 50%;
        padding-right: 40px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

}

.b-site {
    @include media-breakpoint-down(md) {
        overflow-x: hidden;
    }
}

.btn-outline-primary {
    @include media-breakpoint-down(md) {
        background-color: $red !important;
        color: #ffffff !important;
    }
}

.btn {
    display: inline-flex;
    align-items: center;
    &.btn-block {
        display: block;
    }
    @include media-breakpoint-down(md) {
        display: block;
    }
}