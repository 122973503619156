.b-about {
    padding: 65px 0 35px;

    h2, .h2 {
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 1;
        font-weight: 300;
        color: $primary;
    }

    p, .p {
        font-size: 15px;
        font-weight: 500;
        line-height: 22px;
        margin-bottom: 40px;
        color: #1d5291;

        &:last-child {
            margin-bottom: 0;
        }

    }

    h3, .h3 {
        color: $red;
        font-weight: bold;
        font-size: 35px;
        margin-bottom: 20px;
    }

    .li {
        margin-bottom: 25px;
        padding-left: 55px;
        position: relative;
        color: $primary;
        font-weight: 500;
        font-size: 23px;
        line-height: 31px;

        .icon {
            fill: $primary;
            position: absolute;
            top: 7px;
            left: 0;
        }

    }

}
