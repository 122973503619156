.b-modal {

    position: fixed;
    height: 100%;
    overflow: auto;
    width: 100%;
    left: 0;
    top: 0;
    max-height: 100%;
    z-index: 999999999;
    background-color: rgba($primary, .8);
    display: none;

    padding-bottom: 80px;

    &.js-term-modal {
        z-index: 9999999991;
    }

    &__close {
        position: absolute;
        z-index: 9;
        top: 0;
        right: 0;
        width: 70px;
        height: 70px;
        background-color: $primary;
        background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACEAAAAhCAQAAAD97QrkAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAJcEhZcwAACxIAAAsSAdLdfvwAAAAHdElNRQfiBxwQGCcEgKpcAAABRElEQVRIx53VvU7DMBDA8Vtw0gwdeF0WmHgKJLYI0QL9SNpS4H0YQKIgAdOfwU5sJ3YaIy+Rc/fT2bLuhEt+uSFDklfBnB/OhW8AalQy8ATAl1DCP5CCvcm7EhR1MlLwaHLmKMFFqlGIByB6MwWxwJ2ObX5kI5GCnQ9YwkXWUcQC9zbGDcjYDCKTEOATLrLqIRGgS8SRCVuz/9DF++WGkAEgRAh5iyxRCHkLLEJ3FL55F5kOAzHCRd6GgTjhlm+PlEgIp3wY4J1pPG7oKb84VWzJUwkLvB5DYsCzSbylYGG+N2HkGHCCoFi2SDaGaHpiAwiCYhVH4sCsBY4g4wAfqX3EB/ZRQCPrEBIGYi/RRXpdawygkaqLNIDX1geXRSrbwVMAjXjdPh3oIcKsOxcSkbKZ7GmAYOfOp3DBgetkQCMlB87+AKXATIHxwkPmAAAAAElFTkSuQmCC');
        background-repeat: no-repeat;
        background-position: 50% 50%;

        @include media-breakpoint-down(md) {
            width: 55px;
            height: 55px;
            background-size: 50% 50%;
        }

        &:hover {
            background-color: $red;
        }

    }

    &__body {
        position: absolute;
        width: 860px;
        max-width: 90%;
        top: 120px;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        margin-bottom: 80px;

        @include media-breakpoint-down(md) {
            margin-bottom: 40px;
            top: 5%;
        }

        &_small {
            width: 500px;
        }

    }

    .img {
        width: 100%;
        height: 310px;
        display: block;
        object-fit: cover;
    }

    .title {
        margin-top: -80px;
        position: relative;
        z-index: 2;
        display: inline-block;
        background-color: $primary;
        margin-bottom: 0;
        font-size: 50px;
        line-height: 55px;
        font-weight: 300;
        color: $white;
        padding: 20px 55px;

        @include media-breakpoint-down(md) {
            font-size: 35px;
        }
    }

    .sub-title {
        margin-bottom: 20px;
        font-size: 25px;
        line-height: 1;
        color: $primary;
    }

    .section {
        margin-bottom: 30px;
        & + .desc {
            padding-top: 0;
        }
    }

    .text, .form {
        padding: 40px 55px;

        @include media-breakpoint-down(md) {
            padding: 30px 20px;
        }
    }

    .text:first-of-type {
        padding-top: 75px;

        @include media-breakpoint-down(md) {
            padding-top: 60px;
        }

    }

    .form {
        background-color: #f2f2f2;
    }

    .h3, h3 {
        font-size: 25px;
        line-height: 35px;
        color: $primary;
        font-weight: normal;
        margin-bottom: 40px;

        @include media-breakpoint-down(md) {
            font-size: 20px;
            line-height: 28px;
        }

        &.red {
            color: $red;
            &:last-child {
                margin-bottom: 0;
            }
            @include media-breakpoint-down(md) {
                font-size: 18px !important;
            }
        }
    }

    .i-table {
        width: 100%;
        border: 1px solid #8ea8c8;

        thead {
            td, th {
                background-color: #4f83c1 !important;
                color: #ffffff;
                font-size: 18px;
                font-weight: 500;
                text-align: center;
                table-layout: fixed;
            }
        }

        .inner {
            width: 100%;
            td {
                background-color: #4f83c1 !important;
                min-width: 170px;
                &[colspan="3"] {
                    background-color: $primary !important;
                    border-top: none;
                    border-bottom: 1px solid $primary;
                }
                &:last-child {
                    border-right: none;
                }
                table-layout: fixed;
            }
        }

        td {
            text-align: center;
            padding: 10px 0;
            color: $primary;
            font-size: 18px;
            line-height: 1;
            border-right: 1px solid $primary;
            table-layout: fixed;
            &.first {
                width: 30%;
                max-width: 30%;
                min-width: 30%;
            }
        }

        tr:nth-child(odd) td {
            background-color: #f2f2f2;
        }

        .first {
            width: 30%;
            max-width: 30%;
            min-width: 30%;
        }

    }

    .price-light {
        color: #8ea8c8;
        padding: 20px 0 20px;
    }

    .desc {
        font-size: 15px;
        color: $primary;
    }

}


body #pozvonim-button {
    z-index: 9 !important;
}

body .label_39 {
    z-index: 9 !important;
}