.b-network {
    padding: 75px 0 120px;
    background-color: #122a47;
    color: $white;
    background-image: url(../img/network__map.png);
    background-repeat: no-repeat;
    background-position: 50% 0;

    @include media-breakpoint-down(md) {
        background-size: 100% auto;
        background-position: 50% 320px;
        padding-bottom: 50px;
    }

    @include media-breakpoint-down(sm) {
        background-position: 50% 310px;
    }

    h2, .h2 {
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 55px;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 40px;
        }

    }

    .small-text {
        font-size: 23px;
        line-height: 33px;
    }

    .main-text {
        font-size: 35px;
        line-height: 50px;
        font-weight: 300;
        padding-top: 50vh;
        @include media-breakpoint-down(lg) {
            padding-top: 30vh;
        }
        @include media-breakpoint-down(sm) {
            padding-top: 30vh;
        }
    }

}
