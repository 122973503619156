.b-clients {
    padding: 30px 0 60px;

    @include media-breakpoint-down(md) {
        position: relative;

        .sub-title {
            top: 15px !important;
            padding-top: 25px;

        }

        .img {
            margin-top: 75px !important;
        }

        .col-lg-5.col-12 {
            .sub-title {
                display: none;
            }
        }

        .js-fixed-counter {
            position: static !important;
            margin-bottom: 60px;
        }

    }

    &__slider {
        width: calc(100% + 35px);
        margin-left: -35px;
        padding: 35px 0 35px 35px;

        .sub-title, .d-lg-flex.justify-content-start.align-items-center {
            opacity: 0;
        }

        @include media-breakpoint-down(md) {
            margin-left: 0;
            padding: 0;
            width: 100%;
            text-align: center;
            margin-bottom: 30px;

        }

    }

    .img {
        width: 100%;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-left: 0 !important;
        }

    }

    .sub-title {
        margin-bottom: 50px;
        line-height: 1;
        color: $primary;
        font-size: 23px;
        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
            left: 0 !important;
        }
    }

    .js-fixed-counter {
        @include media-breakpoint-down(md) {
            width: 100%;
            left: 0 !important;
        }
    }

    h2, .h2 {
        margin-bottom: 50px;
        font-size: 50px;
        line-height: 55px;
        font-weight: 300;
        color: $primary;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 1.5;
            margin-bottom: 0;
        }

        &.red {
            color: $red;
            display: flex;
            align-items: flex-end;
            line-height: 1;

            @include media-breakpoint-down(md) {
                margin-bottom: 20px;
                display: block;
                line-height: 1.25;
            }

            .small {
                font-weight: normal;
                color: $primary;
                padding-left: 30px;
                font-size: 23px;
                transform: translateY(-4px);
                display: inline-block;
                line-height: 1;

                @include media-breakpoint-down(md) {
                    padding-left: 0;
                    display: block;
                    line-height: 1.3;
                }

            }

        }
    }

    .arrow {

        cursor: pointer;
        outline: none !important;

        .icon {
            fill: $primary;
        }

        &:hover {
            .icon {
                fill: $red;
            }
        }

    }

    .counter {
        padding: 0 40px;
        font-weight: 300;
        font-size: 50px;
        line-height: 1;
        color: $primary;
    }

    .client {
        margin-bottom: 25px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 110px;
        border: 2px solid #eeebf0;
        font-size: 15px;
        line-height: 18px;
        color: $red;
        text-decoration: none;
        text-align: center;
        font-weight: 500;
        transition: $transition-base;
        position: relative;

        .text {
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            padding: 15px;
            background-color: #ffffff;
            opacity: 0;
            transition: $transition-base;
            &_static {
                z-index: 1;
                opacity: 1;
            }
        }

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            content: '';
            border: 4px solid transparent;
            transition: $transition-base;
            z-index: 3;
        }

        &:hover {

            box-shadow: 0 0 18px rgba(157, 153, 160, .48);
            border-color: $red;

            .text {
                opacity: 1;
            }

            &:before {
                border-color: $red;
            }

        }

    }

    .light {
        color: #999;
        padding-top: 2px;
        font-size: .9em;
    }

}
