.b-landing {

    padding: 100px 0 30px;
    background-image: url(../img/landing__bg.jpg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;

    @include media-breakpoint-down(md) {
        padding-top: 0;
        padding-bottom: 60px;
    }

    &__title {

        background-color: $primary;
        color: $white;
        padding: 20px 40px 20px 25px;
        font-size: 50px;
        line-height: 55px;
        min-width: 777px;

        @include media-breakpoint-down(md) {
            padding: 20px;
            width: calc(100% + 30px);
            margin-left: -15px;
            min-width: 0;
            font-size: 35px;
            line-height: 40px;
            margin-bottom: 40px;
        }

    }

    &__subtitle {
        padding: 20px 25px 20px 15px;
        background-color: #ffffff;
        font-size: 25px;
        color: $red;
        line-height: 35px;
        min-width: 777px;

        ul {
            margin-bottom: 0;
        }


        @include media-breakpoint-down(md) {
            font-size: 20px;
            padding: 0;
            width: 100%;
            min-width: 0;
            margin-bottom: 20px;

        }

    }

    .btn {
        margin-left: 50px;
        font-size: 22px;
        padding-left: 40px;
        padding-right: 40px;

        @include media-breakpoint-down(md) {
            margin-left: 0;
            width: 100%;
            margin-top: 20px;
        }
    }

    &__text {
        background-color: $white;
        padding: 40px 0;
        font-size: 20px;
        line-height: 30px;
        color: #1d5291;

        @include media-breakpoint-down(md) {
            padding: 20px 0;
        }

        strong {
            color: $red;
            line-height: 35px;
            font-size: 35px;
            display: block;
            margin-bottom: 10px;
        }

        .item {
            position: relative;

            @include media-breakpoint-down(md) {
                padding-bottom: 30px;
            }

            &:before {
                border-right: 1px solid $primary;
                position: absolute;
                top: -5px;
                bottom: -5px;
                right: 15px;
                content: '';
                @include media-breakpoint-down(md) {
                    display: none;
                }
            }
        }

    }

    &__right {
        border-style: solid;
        border-width: 5px;
        border-color: rgb(174, 62, 22);
        background-color: rgb(255, 255, 255);
        box-shadow: 0 0 18px rgba(157, 153, 160, .48);
        padding: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 25px;
        line-height: 30px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
            text-align: center;
            .btn {
                margin-top: 15px;
            }
        }
    }

    .btn.btn-primary {
        font-size: 20px;
        border: 3px solid $white !important;
        margin-left: 0;

        @include media-breakpoint-down(md) {
            width: 100%;
            padding-left: 15px;
            padding-right: 15px;
        }

        .icon {
            fill: $white;
            margin-right: 10px;
        }

    }

    &__round {
        border-radius: 50%;
        background-color: rgb(255, 255, 255);
        box-shadow: 0px 0px 32.68px 5.32px rgba(68, 75, 84, 0.84);
        width: 182px;
        height: 182px;
        margin: 0 auto 40px;
        font-weight: bold;
        color: #ae3e16;
        font-size: 23px;
        text-align: center;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .info {
            position: absolute;
            width: 175px;
            left: 50%;
            transform: translateX(-50%);
            bottom: -30px;
            background-color: #1d5291;
            color: #ffffff;
            text-align: center;
            font-size: 15px;
            line-height: 20px;
            padding: 5px 0;
        }

        img {
            margin-top: 10px;
        }

    }

    &__desc {
        padding-top: 50px;
        color: #ffffff;
        font-size: 20px;
        line-height: 30px;

        @include media-breakpoint-down(md) {
            display: none;
        }

        .img {
            margin-right: 15px;
            margin-top: 10px;
        }

    }

}
