.b-benefits {
    padding: 65px 0 75px;
    background-color: #122a47;
    color: $white;

    h3, .h3 {
        font-size: 23px;
        font-weight: normal;
        line-height: 35px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
            margin-bottom: 30px;
        }

    }

    h2, .h2 {
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 1;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 35px;
        }

    }

    .btn.btn-primary {
        font-size: 20px;
        border: 3px solid $white !important;

        @include media-breakpoint-down(md) {
            width: 100%;
        }

        .icon {
            fill: $white;
            margin-right: 10px;
        }

    }

    &__cont {
        padding: 60px 0;

        .img {
            width: 100%;

            @include media-breakpoint-down(md) {
                margin-bottom: 30px;
            }

        }

        h2, .h2 {
            margin-bottom: 20px;
            font-size: 50px;
            line-height: 1;
            font-weight: 300;
        }

        .main {
            font-size: 15px;
            line-height: 22px;
            color: rgba($white, 1);
            margin-bottom: 15px;
            font-weight: 500;
        }

        .sub-main {
            font-size: 15px;
            line-height: 22px;
            color: rgba($white, .5);
            margin-bottom: 15px;
            font-weight: 400;
        }

    }

    &__form {
        margin-bottom: 60px;
        background-color: $white;
        padding: 35px 50px;
        color: $primary;

        @include media-breakpoint-down(md) {
            padding: 20px;
        }

        h2, .h2 {
            margin-bottom: 20px;
            font-size: 50px;
            line-height: 1;
            font-weight: 300;
        }

        p, .p {
            margin-bottom: 30px;
            font-size: 15px;
            line-height: 22px;
            font-weight: 500;
        }

        .btn.btn-primary {
            font-size: 22px;
            @include media-breakpoint-down(md) {
                font-size: 16px;
            }
        }

    }

    &__title {
        text-align: center;
        margin-bottom: 20px;
        font-size: 50px;
        line-height: 1;
        font-weight: 300;
    }

    .list-item {
        margin-top: 35px;
        font-size: 23px;
        line-height: 28px;
        padding-left: 38px;
        position: relative;

        @include media-breakpoint-down(md) {
            width: 220px;
            margin-left: auto;
            margin-right: auto;
        }

        .icon {
            position: absolute;
            top: 10px;
            left: 0;
            fill:$white;
        }

    }

    .li-items-cont {
        margin-bottom: 55px;

        @include media-breakpoint-down(md) {
            padding-top: 30px;
        }

    }

    .li-items {

        position: relative;
        font-size: 15px;
        padding-left: 15px;
        line-height: 23px;

        &:before {
            position: absolute;
            top: 0;
            left: 0;
            content: '•';
        }
    }

}
