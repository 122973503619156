.b-catalog {
    padding: 65px 0;
    background-color: $primary;
    color: #ffffff;

    .p {
        font-size: 23px;
        margin-bottom: 20px;
        line-height: 1;
    }

    h2, .h2 {
        font-size: 50px;
        line-height: 1;
        margin-bottom: 65px;
        font-weight: 300;

        @include media-breakpoint-down(md) {
            font-size: 35px;
        }

    }

    &__item {
        margin-bottom: 50px;
        height: 380px;
        background-color: $white;
        width: calc(50% - 25px);
        transition: $transition-base;

        @include media-breakpoint-down(md) {
            width: 100%;
            margin-bottom: 25px;
            height: auto;
        }

        .img {
            width: 100%;
            height: 238px;
            display: block;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .text {
            padding: 25px;
            color: $primary;
        }

        .title {
            display: block;
            margin-bottom: 20px;
            font-size: 25px;
            line-height: 1;

            @include media-breakpoint-down(md) {
                font-size: 18px;
                text-align: center;
            }
        }

        .price {
            line-height: 1;
            font-size: 35px;
            font-weight: bold;
            color: $red;

            @include media-breakpoint-down(md) {
                font-size: 20px;
                margin-bottom: 10px;
            }

            &_small {
                font-size: 24px;

                @include media-breakpoint-down(md) {
                    font-size: 20px;
                }
            }
        }

        &:hover {
            box-shadow: 0 0 38px rgba(darken($primary, 10%), .8);
        }

        .btn {
            @include media-breakpoint-down(md) {
                width: 100%;
                display: block;
            }
        }

    }

    &__tabs {
        padding-top: 40px;

        .tabs-header {

            width: 50%;
            text-align: center;
            font-weight: 300;
            font-size: 50px;
            padding-bottom: 35px;
            border-bottom: 1px solid rgba($white, .5);
            cursor: pointer;
            position: relative;

            @include media-breakpoint-down(md) {
                font-size: 16px;
            }

            &:hover {
                color: $red;
            }

            &.active {

                cursor: default;
                &:before {
                    position: absolute;
                    bottom: -3px;
                    left: 0;
                    right: 0;
                    height: 5px;
                    background-color: $white;
                    content: '';
                }

                &:hover {
                    color: $white;
                }

            }

        }

        .tabs-body {
            font-size: 15px;
            color: rgba($white, .5);
            line-height: 1.466;
            padding-top: 55px;

            .icon {
                margin-bottom: 25px;
                display: block;
                fill: $white;
                width: 30px;
                max-width: 30px;
                min-width: 30px;
                flex: 30px;

                @include media-breakpoint-down(md) {
                    margin-bottom: 0;
                    margin-right: 20px;
                }

            }

            .col-12 {
                @include media-breakpoint-down(md) {
                    padding-bottom: 20px;
                    display: flex;
                    align-items: center;
                }
            }

        }

    }

}
