.icon-arrow {
  width: 30.031px;
  height: 22.03px;
  fill: initial;
}
.icon-arrow2 {
  width: 18.969px;
  height: 13.97px;
  fill: initial;
}
.icon-doc {
  width: 31.031px;
  height: 32px;
  fill: initial;
}
.icon-nav-left {
  width: 17px;
  height: 34px;
  fill: initial;
}
.icon-nav-right {
  width: 17px;
  height: 34px;
  fill: initial;
}
